import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import * as S from "../components/SuccessAndNotFound/styled";

const SuccessPage = () => (
  <Layout>
    <SEO title="Dados enviados" />
    <S.SectionWrapper>
      <S.SectionContent>
        <h1>Dados enviados!</h1>
        <h2>Obrigado! Em breve entraremos em contato com você. </h2>
      </S.SectionContent>
      <S.SectionLink to="/">Voltar para a página inicial</S.SectionLink>
    </S.SectionWrapper>
  </Layout>
);

export default SuccessPage;
